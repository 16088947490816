import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import EditImageComponent from "../../components/editImage/EditImageComponent";
import config from "../../config/main.config";
import EditTextComponent from "../../components/editText/EditTextComponent";
import {jumpToTop} from "../../helper/util";
import {loadTextsByNamespace} from "../../../server/helper/ssrHelper";
import history from "../../helper/browserHistory";
import i18n from "i18next";

const namespace='Angebote'

const AngebotePage = () => {

    useEffect(() => {
        if (typeof window !== "undefined") {
            jumpToTop(window);
        }

    }, []);

    return (
        <div className={'grid ui'} style={{paddingTop: '300px'}}>
            <div style={{display: 'none'}}>
                <BreadCrumbs breadCrumbData={[{title: 'Angebote', url: '/angebote'}]}/>
            </div>
            <div className={'row centered page-content-wrapper'}>
                <div className={'nine wide computer twelve wide mobile column'}>
                    <div style={{padding: '5rem'}}>
                        <EditImageComponent id={'Angebote-image-1'}/>
                    </div>
                </div>
                <div className={'seven wide computer twelve wide mobile column margin-top-bottom'}>
                    <h1><EditTextComponent id={'Angebote-header-1'} namespace={'about'}/></h1>
                    <p>
                        <EditTextComponent id={'Angebote-text-1'} namespace={'about'}/>
                    </p>
                </div>

                <div className={'seven wide computer twelve wide mobile column margin-top-bottom'}>
                    <h1><EditTextComponent id={'Angebote-header-2'} namespace={'about'}/></h1>
                    <p>
                        <EditTextComponent id={'Angebote-text-2'} namespace={'about'}/>
                    </p>
                </div>
                <div className={'nine wide computer twelve wide mobile column'}>
                    <div style={{padding: '5rem'}}>
                        <EditImageComponent id={'Angebote-image-2'}/>
                    </div>
                </div>
                <div className={'nine wide computer twelve wide mobile column'}>
                    <div style={{padding: '5rem'}}>
                        <EditImageComponent id={'Angebote-image-3'}/>
                    </div>
                </div>
                <div className={'seven wide computer twelve wide mobile column margin-top-bottom'}>
                    <button className={'button-1'} onClick={_=>{history.push(`/${i18n.language}/contact`)}}>KONTAKT ></button>
                </div>
            </div>
        </div>
    )
}

AngebotePage.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'Angebote').then((text) => {
        return text;
    })
}

export default withTranslation()(AngebotePage);